// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-doc-template-js": () => import("/opt/build/repo/website/src/templates/DocTemplate.js" /* webpackChunkName: "component---src-templates-doc-template-js" */),
  "component---src-templates-example-template-js": () => import("/opt/build/repo/website/src/templates/ExampleTemplate.js" /* webpackChunkName: "component---src-templates-example-template-js" */),
  "component---src-templates-recipe-template-js": () => import("/opt/build/repo/website/src/templates/RecipeTemplate.js" /* webpackChunkName: "component---src-templates-recipe-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-examples-js": () => import("/opt/build/repo/website/src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-js": () => import("/opt/build/repo/website/src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/website/.cache/data.json")

